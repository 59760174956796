import { useHistory, useParams } from "react-router-dom";
import { useEffect } from 'react';


export default function Login(props) {
    const { connection_id } = useParams()
    const history = useHistory()

    useEffect(() => {
        props.setConnectionId(connection_id)
        history.push('/login')
    }, [props, connection_id, history])

    return (
        <div className="image-component m-auto col-sm-6 col-md-5 text-center">
            <div className="spinner-border m-auto" role="status">
                <span className="sr-only">Carregando...</span>
            </div>
        </div>
    )
}