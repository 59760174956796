import React, { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { useHistory, Link } from 'react-router-dom';
import axios from 'axios';

export default function LoginForm(props) {
    const { register, control, errors, handleSubmit } = useForm();
    const history = useHistory()

    const [sending, setSending] = useState(true)
    const [client, setClient] = useState({})

    const changeToQuestion = () => {
        history.push("/login/question")
    }

    useEffect(() => {
        const getClient = async () => {
            try {
                const {data} = await axios.post('/api/wifilogin/check_mac/', {
                        connection_id: props.connectionId
                    }
                )
                console.log(data)
                setClient(data)
                setSending(false)
            } catch (error) {
                console.log(error)

                if(error.response) {
                    if (error.response.status !== 404) {
                        history.push("/login/error")
                    }
                } else {
                    history.push("/login/error")
                }
                setSending(false)
            }
        }

        getClient()
    }, [history, props])

    const onSubmit = data => {
        setSending(true)
        const sendData = async () => {
            try {
                const response = await axios.post('/api/wifilogin/client_login/', {
                    client: {
                        name: data.name,
                        email: data.email,
                        phone: data.phone
                    },
                    connection_id: props.connectionId
                })
                console.log(response)
                history.push("/login/question")
            } catch (error) {
                console.log(error)
                history.push("/login/error")
            }
        }

        sendData()
    }

    return !sending ? (
            client.name ? (
                    <div className="form-component m-auto col-sm-6 col-md-5 text-center">
                        <h1 className="h2 mb-5">Oi, {client.name.split(' ')[0]}!</h1>
                        <h2 className="h3 mb-5">Estamos felizes em ver você aqui outra vez.</h2>
                        <button onClick={changeToQuestion} className="btn btn-primary btn-block mt-5">Continuar</button>
                    </div>
                ) : (
                    <div className="form-component m-auto col-sm-6 col-md-5">
                        <h1 className="h2 text-center">É bom ter você aqui!</h1>
                        <p className="text-center">Preencha os campos abaixo para ter acesso à internet.</p>
                        <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input type="email" className={errors.email ? "form-control is-invalid" : "form-control"} name="email" id="email" ref={register({ required: true, pattern: /^\S+@\S+$/i, minLength: 3, maxLength: 50 })} />
                                <div className="invalid-feedback">
                                    Informe um email válido!
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="name">Nome</label>
                                <input type="text" className={errors.name ? "form-control is-invalid" : "form-control"} name="name" id="name" ref={register({ required: true, minLength: 3, maxLength: 80 })} />
                                <div className="invalid-feedback">
                                    Informe um nome válido!
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="phone">Celular com DDD</label>
                                <input type="tel" className={errors.phone ? "form-control is-invalid" : "form-control"} name="phone" id="phone" ref={register({ required: true, pattern: /^[^_]*$/, minLength: 11, maxLength: 11 })} />
                                {/* /*{ { <Controller
                                    name="phone"
                                    control={control}
                                    rules={{ required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, minLength: 14, maxLength: 14 }}
                                    defaultValue={false}
                                    render={(field) => (
                                        <InputMask
                                          {...field}
                                          id="phone"
                                          mask="(99)99999-9999" 
                                          maskChar=" "
                                          className={errors.phone ? "form-control is-invalid" : "form-control"}
                                        />
                                      )} }
                                    render={({ field }) => <PatternFormat format="(##)#####-####" mask="_" type="tel" id="phone" className={errors.phone ? "form-control is-invalid" : "form-control"} {...field} />}
                                    render={(props) => (
                                        <PatternFormat format="(##)#####-####" mask="_" type="tel" id="phone" className={errors.phone ? "form-control is-invalid" : "form-control"} {...props}/>
                                      )}
                                    as={<PatternFormat format="(##)#####-####" mask="_" type="tel" id="phone" className={errors.phone ? "form-control is-invalid" : "form-control"} />}
                                /> } */}
                                <div className="invalid-feedback">
                                    Informe um celular válido!
                                </div>
                            </div>
                            <div className="form-group form-check">
                                <input type="checkbox" className={errors.privacypolice ? "form-check-input is-invalid" : "form-check-input"} name="privacypolice" id="privacypolice" ref={register({ required: true })} />
                                <label htmlFor="privacypolice" className='form-check-label'>Aceito os termos da <Link to={'/login/privacypolice'}>política de privacidade</Link>.</label>
                                <div className="invalid-feedback">
                                    É obrigatório aceitar os termos da política de privacidade.
                                </div>
                            </div>
                            {console.log(errors)}
                            <button type="submit" className="btn btn-primary btn-block">Entrar</button>
                        </form>
                    </div>
                    )
                ) : (
                    <div className="image-component m-auto col-sm-6 col-md-5 text-center">
                        <div className="spinner-border m-auto" role="status">
                            <span className="sr-only">Carregando...</span>
                        </div>
                    </div>
                )
}