import LoginForm from "./forms/LoginForm";
import Ad from "./components/Ad";
import QuestionForm from "./forms/QuestionForm";
import Error from "./components/Error";
import Success from "./components/Success";
import Login from "./components/Login";
import PrivacyPolice from "./components/PrivacyPolice";

import { useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

function App() {
      const [showAd, setShowAd] = useState(true)
      const [connectionId, setConnectionId] = useState({})

      return (
          <BrowserRouter>
              <div>
                  <div className="row mx-auto d-flex justify-content-center login-box">
                      {(
                          Object.keys(connectionId).length !== 0 && showAd ?
                            <Ad connectionId={connectionId} />
                            : ""
                      )}
                      <Switch>
                          <Route path="/login/privacypolice">
                              <PrivacyPolice setShowAd={setShowAd} />
                          </Route>
                          <Route path="/login/question">
                              <QuestionForm connectionId={connectionId} />
                          </Route>
                          <Route path="/login/error">
                              <Error />
                          </Route>
                          <Route path="/login/success">
                              <Success connectionId={connectionId} />
                          </Route>
                          <Route path="/login/:connection_id">
                              <Login setConnectionId={setConnectionId} />
                          </Route>
                          <Route path="/login">
                              <LoginForm connectionId={connectionId} />
                          </Route>
                      </Switch>
                  </div>
              </div>
          </BrowserRouter>
      );
}

export default App;
