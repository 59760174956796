import React, { useEffect, useState } from 'react';
import axios from 'axios';

export default function Ad(props) {
    const initialAdState = {
        ad: {},
    }

    const [ad, setAd] = useState(initialAdState)

    useEffect(() => {
        const getAd = async () => {
            try {
                const { data } = await axios.post('/api/ad/get_random/', {
                        connection_id: props.connectionId
                    }
                )
                console.log(props.connectionId)
                console.log(data)
                setAd(data)
            } catch (error) {
                console.log(error)
            }
        }

        getAd()
    }, [props])

    return ad.photo ? (
        <div className="image-component my-auto col-sm-6 col-md-7">
            <img className="w-100" src={ad.photo} alt={ad.title} />
        </div>
    ) : (
        <div className="image-component m-auto col-sm-6 col-md-5 text-center">
            <div className="spinner-border m-auto" role="status">
                <span className="sr-only">Carregando...</span>
            </div>
        </div>
    )
}