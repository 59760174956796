import React from 'react';
import ReactDOM from 'react-dom';
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css';
import $ from 'jquery';
import Popper from "popper.js";
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://0fbe9bda29bc4353839f7d3d6c672aa4@o4503960082186240.ingest.sentry.io/4503960085069824",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
});

axios.defaults.baseURL = "https://wifiup.wifilabs.com.br";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
