import React from "react";

export default function Success(props) {

    const onSubmit = () => {
        const sendData = () => {
            window.location.href = "https://wifiup.wifilabs.com.br/api/wifilogin/internet_redirect/?c=" + props.connectionId
        }

        sendData()
    }

    return (
        <div className="form-component m-auto col-sm-6 col-md-5 text-center">
            <h1 className="h2 mb-5">Tudo certo!</h1>
            <h2 className="h3 mb-5">Agora clique no botão abaixo para acessar a internet.</h2>
            <button onClick={onSubmit} className="btn btn-primary btn-block mt-5">Conectar</button>
        </div>
    )
}