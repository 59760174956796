import React from "react";

export default function Error() {
    return (
        <div className="form-component m-auto col-sm-6 col-md-5 text-center">
            <h1 className="h2 mb-4">Ops!</h1>
            <p className="h4 mb-4">Ocorreu um erro em nosso servidor.</p>
            <p className="h4">Por favor, se conecte novamente à rede.</p>
        </div>
    )
}