import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import axios from 'axios';

export default function QuestionForm(props) {
    const { register, errors, handleSubmit } = useForm();
    const history = useHistory();
    const initialPollState = {};

    const [sending, setSending] = useState(true)
    const [poll, setPoll] = useState(initialPollState);
    const connection_id = props.connectionId;

    useEffect(() => {
        setSending(true)
        const getQuestion = async () => {
            try {
                const {data} = await axios.post('/api/poll/get_poll/', {
                        connection_id: connection_id
                    }
                )
                console.log(data)
                setPoll(data)
                setSending(false)
            } catch (error) {
                console.log(error)

                if(error.response) {
                    history.push("/login/success")
                } else {
                    history.push("/login/error")
                }
            }
        }

        getQuestion()
    }, [history, connection_id])

    const onSubmit = data => {
        setSending(true)
        const sendData = async () => {
            try {
                const response = await axios.post('/api/answer/save_answer/', {
                        connection_id: connection_id,
                        option_id: data.answer
                    }
                )
                console.log(response)
                history.push("/login/success")
            } catch (error) {
                console.log(error)
                history.push("/login/error")
            }
        }

        sendData()
    }

    return !sending ? (
            <div className="form-component m-auto col-sm-6 col-md-5 text-center">
                <h1 className="h2 mb-5">Só mais uma coisa!</h1>
                <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
                    <h3 className="mb-3">{poll.question}</h3>
                    <div className="form-group">
                        {
                            poll.options.map((option) => (
                                <div key={option.id} className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="answer" id={option.id} value={option.id} ref={register({ required: true })} />
                                    <label className="form-check-label" htmlFor={option.id}>
                                        {option.option}
                                    </label>
                                </div>
                            ))
                        }
                        <input type="hidden" className={ errors.answer ? "is-invalid" : "" } />
                        <div className="invalid-feedback">
                            Escolha uma opção!
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary btn-block mt-5">Enviar</button>
                </form>
            </div>
        ) : (
            <div className="image-component m-auto col-sm-6 col-md-5 text-center">
                <div className="spinner-border m-auto" role="status">
                    <span className="sr-only">Carregando...</span>
                </div>
            </div>
    )
}